import { classnames } from '@/core/utils/bem-classname';

import styles from './looped-loading-bar.module.scss';

export default function LoopedLoadingBar({
    className,
    style,
    width = 'full-width',
}: {
    className?: string;
    style?: React.CSSProperties;
    width?: 'full-width' | 'width-100';
}) {
    return (
        <div
            className={classnames(styles.progressBar, width, className, {
                'left-0': width === 'width-100',
            })}
            style={style}
        >
            <div className={styles.loopedLoadingBar} />
        </div>
    );
}
