import type { IconWrapperProps } from '@/core/components/icon/icon-wrapper';
import type { SVGIconProps } from '@/core/components/icon/svg-icon';

import { IconWrapper } from '@/core/components/icon/icon-wrapper';
import SVGIcon from '@/core/components/icon/svg-icon';

export default function WishlistButtonIcon(props: SVGIconProps & IconWrapperProps) {
    return (
        <IconWrapper {...props}>
            <SVGIcon
                {...props}
                viewBox={'0.5 0.5 20 18'}
            >
                <path
                    d={
                        'M14.9, 1L15.94, 1.11L16.9, 1.42L17.77, 1.9L18.52, 2.55L19.14, 3.34L19.61, 4.24L19.9, 5.24L20, 6.32L19.88, 7.41L19.49, 8.49L18.83, 9.62L17.86, 10.85L16.56, 12.25L14.92, 13.87L12.9, 15.77L10.5, 18L8.1, 15.77L6.08, 13.87L4.44, 12.25L3.14, 10.85L2.17, 9.61L1.51, 8.48L1.12, 7.4L1, 6.32L1.1, 5.25L1.4, 4.25L1.87, 3.34L2.5, 2.56L3.26, 1.91L4.13, 1.42L5.09, 1.11L6.12, 1L7.47, 1.18L8.67, 1.7L9.69, 2.5L10.5, 3.53L11.32, 2.5L12.35, 1.7L13.56, 1.18L14.9, 1'
                    }
                    vectorEffect={'non-scaling-stroke'}
                />
            </SVGIcon>
        </IconWrapper>
    );
}
