import React from 'react';
import { useInView } from 'react-intersection-observer';

import { IS_NODE_ENV_DEV } from '@/constants/env';
import useComponentMinHeightRegistryState from '@/core/features/lazy/use-lazy-component-min-height';
import { useRouterNavigatingState } from '@/core/features/router/router-link';
import useIsScrolling from '@/core/features/scroll/use-is-scrolling';

type LazyProps = React.PropsWithChildren<{
    componentIndex: number;
    componentType: string;
    disableSkeleton?: boolean; // use when lazy component should not render skeleton, e.g. white background box
    initialInView?: boolean; // render the element immediately
    minHeight: number;
    minWidth?: number;
    rootMargin?: string; // offset for when the element is considered in view
    rootRef?: React.RefObject<HTMLElement | null>; // useful for fixed/absolute overlays that need to be given for rootMargin to work
    skeletonBackgroundColor?: 'bg-transparent' | 'bg-white';
    triggerOnce?: true; // do not remove the element again when it was rendered
}>;

export default function LazyComponent(props: LazyProps) {
    const {
        children,
        componentIndex,
        componentType: _componentType,
        disableSkeleton,
        initialInView = false,
        minHeight: _minHeight,
        minWidth,
        rootMargin = '2000px',
        rootRef,
        skeletonBackgroundColor = 'bg-white',
        triggerOnce = false,
    } = props;

    const { minHeightState, setMinHeightState } = useComponentMinHeightRegistryState({
        componentType: _componentType,
        pageComponentIndex: componentIndex,
    });

    const minHeight = minHeightState ?? _minHeight;

    const [root, setRoot] = React.useState(rootRef?.current);
    const lazyElementRef = React.useRef<HTMLDivElement | null>(null);
    const wasInView = React.useRef(false);
    const isScrolling = useIsScrolling();
    const isRouterNavigating = useRouterNavigatingState();

    // root needs to be set through state because otherwise is will not rerender
    React.useEffect(() => {
        setRoot(rootRef?.current);
    }, [rootRef]);

    const { inView, ref: inViewRef } = useInView({
        initialInView,
        root,
        rootMargin,
        triggerOnce,
    });

    const blockTransition = wasInView.current && (isScrolling || isRouterNavigating);
    const show = inView || blockTransition; //  || isForceRendered;

    React.useEffect(() => {
        wasInView.current = show;
    }, [show]);

    // combine refs
    // https://www.npmjs.com/package/react-intersection-observer/v/8.33.0#user-content-how-can-i-assign-multiple-refs-to-a-component
    const setRefs = React.useCallback(
        (node: HTMLDivElement) => {
            // Ref's from useRef needs to have the node assigned to `current`
            lazyElementRef.current = node;
            // Callback refs, like the one from `useInView`, is a function that takes the node as an argument
            inViewRef(node);
        },
        [inViewRef],
    );

    const updateSizes = React.useCallback(() => {
        if (!show) {
            return;
        }

        if (!lazyElementRef.current) {
            return;
        }

        const renderedElement = lazyElementRef.current.children[0];

        if (!renderedElement) {
            return;
        }

        const { height: updatedMinHeight } = renderedElement?.getBoundingClientRect() ?? {};

        if (minHeight !== updatedMinHeight) {
            setMinHeightState(updatedMinHeight);
        }
    }, [show, minHeight, setMinHeightState]);

    React.useEffect(() => {
        if (!show) {
            return;
        }
        updateSizes();
    }, [show, initialInView, updateSizes]);

    // set white background on components that are not rendered (out of view). This way we show a simple skeleton
    // when a component comes into view but the device is too slow to render the full component in time
    const backgroundColor = !disableSkeleton && !show ? skeletonBackgroundColor : '';

    return (
        <div
            className={`flex-center width-100 ${backgroundColor}`}
            data-component-index={IS_NODE_ENV_DEV ? componentIndex : undefined}
            data-qa-id={`qa-lazy-component-${_componentType}`}
            ref={setRefs}
            style={{
                minHeight: minHeight ? `${minHeight}px` : undefined,
                minWidth,
            }}
        >
            {show && children}
        </div>
    );
}
