import React from 'react';

import {
    decrementWishlistCount,
    incrementWishlistCount,
} from '@/core/features/wireframe/services/wireframe-wishlist-service';
import { useWishlistSelectItemState } from '@/features/wishlist/wishlist-button/hooks/wishlist-state';

type UseWishlistStateProps = {
    isInitiallyActive: boolean;
    wishlistId: number;
};

export function useWishlistItemState({ isInitiallyActive, wishlistId }: UseWishlistStateProps) {
    const [wishlistItems, setWishlistItems] = useWishlistSelectItemState();
    const isActive =
        wishlistItems[wishlistId] === true || (isInitiallyActive && wishlistItems[wishlistId] === undefined);

    const activateItem = React.useCallback(() => {
        setWishlistItems({ ...wishlistItems, [wishlistId]: true });
        incrementWishlistCount();
    }, [setWishlistItems, wishlistItems, wishlistId]);

    const deactivateItem = React.useCallback(() => {
        setWishlistItems({ ...wishlistItems, [wishlistId]: false });
        decrementWishlistCount();
    }, [setWishlistItems, wishlistItems, wishlistId]);

    return { activateItem, deactivateItem, isActive };
}
