import type { IconWrapperProps } from '@/core/components/icon/icon-wrapper';
import type { SVGIconProps } from '@/core/components/icon/svg-icon';

import { IconWrapper } from '@/core/components/icon/icon-wrapper';
import SVGIcon from '@/core/components/icon/svg-icon';

export default function InfoIcon(props: SVGIconProps & IconWrapperProps) {
    return (
        <IconWrapper {...props}>
            <SVGIcon
                {...props}
                viewBox={'0 0 14 14'}
            >
                <path
                    d={
                        'M7 0a7 7 0 1 0 0 14A7 7 0 0 0 7 0zm0 12.645a5.645 5.645 0 1 1 0-11.29 5.645 5.645 0 0 1 0 11.29zM7 3.31a.98.98 0 1 1 0 1.96.98.98 0 0 1 0-1.96zm.56 7.302H6.44a.339.339 0 0 1-.339-.338V6.44a.339.339 0 0 1 .339-.343h1.12a.339.339 0 0 1 .343.343v3.835a.339.339 0 0 1-.343.338z'
                    }
                />
            </SVGIcon>
        </IconWrapper>
    );
}
