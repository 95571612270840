import type {
    ActivitySpot_WishlistNotification_jsonld_wishlist_notification_read,
    ActivitySpotWishlistPostNextApiRequest,
} from '@/features/wishlist/activity-spot-wishlist-post/activity-spot-wishlist-post-type';
import type { AxiosError, AxiosResponse } from 'axios';

import React from 'react';

import { useNotification } from '@/core/components/notification/notification-state';
import { useUser } from '@/core/features/app/app-atoms';
import { useDeviceoutput } from '@/core/features/cookies/use-device-output';
import { useMutation } from '@/core/features/react-query/react-query-service';
import { clientSideApiPostRequest } from '@/core/features/request/client-side-request';
import {
    openUnifiedLogin,
    registerUnifiedLogin,
} from '@/core/features/wireframe/components/unified-login/unified-login-service';
import { useWishlistItemState } from '@/features/wishlist/wishlist-button/hooks/use-wishlist-item-state';
import useWishlistLoginRefresh from '@/features/wishlist/wishlist-button/hooks/use-wishlist-login-refresh';
import useWishlistUrl from '@/features/wishlist/wishlist-button/hooks/use-wishlist-url';

type UseWireframeWishlistProps = {
    isInitiallyActive: boolean;
    wishlistId: number;
};

export default function useWishlistItem({ isInitiallyActive, wishlistId }: UseWireframeWishlistProps) {
    const { activateItem, deactivateItem, isActive } = useWishlistItemState({
        isInitiallyActive,
        wishlistId,
    });
    const { wishlistUrl } = useWishlistUrl();
    const [user] = useUser();
    const { isApp } = useDeviceoutput();
    const [_, setNotification] = useNotification();
    const { data, isError, isPending, isSuccess, mutate, reset } = useMutation<
        AxiosResponse<ActivitySpot_WishlistNotification_jsonld_wishlist_notification_read>,
        AxiosError,
        ActivitySpotWishlistPostNextApiRequest
    >({
        mutationFn: (request: ActivitySpotWishlistPostNextApiRequest) =>
            clientSideApiPostRequest<ActivitySpot_WishlistNotification_jsonld_wishlist_notification_read>(
                '/post/activity-spot-wishlist',
                request,
            ),
        mutationKey: ['activity-spot-wishlist-post'],
    });

    const addItem = React.useCallback(() => {
        mutate({ action: 'add', activitySpotId: wishlistId });

        if (!isActive) {
            activateItem();
        }
    }, [activateItem, isActive, mutate, wishlistId]);

    const { registerLoginRefreshHandler } = useWishlistLoginRefresh({
        callback: addItem,
        wishlistId,
    });

    const addToWishlist = React.useCallback(() => {
        if (isPending || isActive) {
            return;
        }

        if (user.loginStatus === 'active') {
            addItem();
            return;
        }

        registerUnifiedLogin({
            onSuccess: () => {
                registerLoginRefreshHandler();
                activateItem();
            },
            triggerOnce: true,
            withLoginLayer: !isApp,
        });
        openUnifiedLogin({ isApp });
    }, [activateItem, addItem, isActive, isApp, isPending, registerLoginRefreshHandler, user.loginStatus]);

    const removeFromWishlist = React.useCallback(() => {
        if (isPending || !isActive) {
            return;
        }

        mutate({ action: 'remove', activitySpotId: wishlistId });
        deactivateItem();
    }, [deactivateItem, isPending, isActive, mutate, wishlistId]);

    const showNotification = React.useCallback(
        (data: ActivitySpot_WishlistNotification_jsonld_wishlist_notification_read) => {
            const { actionText, notificationText } = data;

            setNotification({
                button: {
                    title: actionText,
                    ...(isActive ? { linkType: 'external', linkUrl: wishlistUrl } : { onClick: addItem }),
                },
                message: notificationText,
            });
        },
        [addItem, isActive, setNotification, wishlistUrl],
    );

    const revertWishlistState = React.useCallback(() => {
        if (isActive) {
            deactivateItem();
        } else {
            activateItem();
        }
    }, [activateItem, deactivateItem, isActive]);

    React.useEffect(() => {
        if (isPending) {
            return;
        }

        if (isSuccess && data?.data) {
            showNotification(data.data);
            reset();
            return;
        }

        if (isError) {
            revertWishlistState();
            reset();
        }
    }, [data, isError, isPending, isActive, isSuccess, reset, revertWishlistState, showNotification, wishlistId]);

    return { addToWishlist, isActive, removeFromWishlist };
}
