import React from 'react';

import WishlistButtonIcon from '@/assets/icons/wishlist-button';
import useWishlistItem from '@/features/wishlist/wishlist-button/hooks/use-wishlist-item';
import { wishlistVariants } from '@/features/wishlist/wishlist-button/wishlist-button-variants';

export type WishlistButtonProps = {
    isInitiallySelected?: boolean;
    type: keyof typeof wishlistVariants;
    wishlistId: number;
};

export default function WishlistButton({ isInitiallySelected = false, type, wishlistId }: WishlistButtonProps) {
    const [isHoverEnabled, setIsHoverEnabled] = React.useState(true);
    const { addToWishlist, isActive, removeFromWishlist } = useWishlistItem({
        isInitiallyActive: isInitiallySelected,
        wishlistId,
    });
    const { button, icon } = wishlistVariants[type];

    const iconHoverColor = (() => {
        if (!isHoverEnabled) {
            return undefined;
        }
        if (isActive) {
            return icon.activeHoverColor;
        }
        return icon.inactiveHoverColor;
    })();

    const onClick = (event: React.MouseEvent) => {
        event.preventDefault();
        event.stopPropagation();
        setIsHoverEnabled(false);

        if (isActive) {
            removeFromWishlist();
        } else {
            addToWishlist();
        }
    };

    return (
        <div
            className={'flex-center'}
            onClick={onClick}
            onMouseLeave={() => setIsHoverEnabled(true)}
            style={{
                WebkitFilter: icon.shadowEffect ? icon.shadowEffect : undefined,
                backgroundColor: button.backgroundColor,
                border: button.outlineColor ? `1px solid ${button.outlineColor}` : undefined,
                borderRadius: button.outlineColor ? '50%' : undefined,
                // eslint-disable-next-line no-inline-styles/no-inline-styles
                cursor: 'pointer',
                filter: icon.shadowEffect ? icon.shadowEffect : undefined,
                height: button.height ? `${button.height}px` : undefined,
                paddingTop: button.paddingTop ? `${button.paddingTop}px` : undefined,
                width: `${button.width}px`,
            }}
        >
            <WishlistButtonIcon
                color={isActive ? icon.activeFillColor : icon.inactiveFillColor}
                height={icon.height}
                hoverColor={iconHoverColor}
                stroke={isActive ? icon.activeStrokeColor : icon.inactiveStrokeColor}
                strokeWidth={icon.strokeWidth}
                width={icon.width}
            />
        </div>
    );
}
