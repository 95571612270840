import type { IconWrapperProps } from '@/core/components/icon/icon-wrapper';
import type { SVGIconProps } from '@/core/components/icon/svg-icon';

import { IconWrapper } from '@/core/components/icon/icon-wrapper';
import SVGIcon from '@/core/components/icon/svg-icon';

export default function SpinnerIcon(props: SVGIconProps & IconWrapperProps) {
    return (
        <IconWrapper {...props}>
            <SVGIcon
                {...props}
                viewBox={'0 0 50 50'}
            >
                <path
                    d={
                        'M44 25C44 35.4934 35.4934 44 25 44C14.5066 44 6 35.4934 6 25H0C0 38.8071 11.1929 50 25 50C38.8071 50 50 38.8071 50 25C50 11.1929 38.8071 0 25 0V6C35.4934 6 44 14.5066 44 25Z'
                    }
                />
            </SVGIcon>
        </IconWrapper>
    );
}
