import React from 'react';

import { NEXT_PUBLIC_CHECK24_DOMAIN } from '@/constants/env';
import { useDeviceoutput } from '@/core/features/cookies/use-device-output';

const WISHLIST_URL_DESKTOP = `https://kundenbereich.${NEXT_PUBLIC_CHECK24_DOMAIN}/user/lists.html`;
const WISHLIST_URL_MOBILE = `https://m.${NEXT_PUBLIC_CHECK24_DOMAIN}/kundenbereich/lists`;

export default function useWishlistUrl() {
    const { deviceoutputMapped } = useDeviceoutput();

    const wishlistUrl = React.useMemo(
        () => (deviceoutputMapped === 'desktop' ? WISHLIST_URL_DESKTOP : WISHLIST_URL_MOBILE),
        [deviceoutputMapped],
    );

    return { wishlistUrl };
}
