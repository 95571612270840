/* eslint-disable react-hooks/rules-of-hooks */

import { HtmlTextWrapper, IconImage, InfoIconModal, InfoIconTooltip } from '@/core/features';
import { logger } from '@/core/features/logger/logger';
import { bemModule, classnames } from '@/core/utils/bem-classname';

import styles from './key-fact.module.scss';

const bem = bemModule(styles);

export type KeyFactProps = {
    fontSize?: 'font-size-12' | 'font-size-14' | 'font-size-15' | 'font-size-16';
    htmlText?: string;
    iconGap?: 'gap-5' | 'gap-10' | 'gap-12' | 'gap-18';
    iconSize?: 12 | 14 | 15 | 16 | 17 | 18 | 20;
    iconUrl: null | string;
    info?: {
        htmlInfoText: string;
        htmlInfoTitle: null | string;
    } | null;
    isDesktopView?: boolean;
    lineHeight?: number;
    offset?: [number, number];
    qaId?: string;
    reserveIconSpace?: boolean;
    showIcon?: boolean;
    text?: string;
    textAlignment?: 'center' | 'start';
    truncation?: 'ellipsis' | 'none' | 'oneLine' | 'twoLines';
};

export default function _KeyFact({
    fontSize,
    htmlText,
    iconGap = 'gap-10',
    iconSize = 18,
    iconUrl,
    info,
    isDesktopView = false,
    lineHeight,
    offset = [0, 36],
    qaId,
    reserveIconSpace = false,
    showIcon = true,
    text,
    textAlignment = 'center',
    truncation = 'none',
}: KeyFactProps) {
    if (!htmlText && !text) {
        logger.error('KeyFact: Found keyfact without text or htmlText. ');
        return null;
    }

    return (
        <div
            className={classnames(styles.container, iconGap, fontSize)}
            style={{ lineHeight }}
        >
            {!iconUrl && reserveIconSpace && (
                <span
                    className={styles.icon}
                    style={{ height: iconSize, width: iconSize }}
                ></span>
            )}
            {showIcon && iconUrl && (
                <span className={styles.icon}>
                    <IconImage
                        height={iconSize}
                        url={iconUrl}
                        width={iconSize}
                    />
                </span>
            )}

            <div className={bem(styles.text, { textAlignment, truncation })}>
                {text && text}
                {htmlText && <HtmlTextWrapper htmlText={htmlText} />}
                {info && (
                    <span
                        className={styles.infoIcon}
                        data-qa-id={'qa-key-fact-info-icon'}
                    >
                        &#xfeff;
                        {isDesktopView ? (
                            <InfoIconTooltip
                                className={'inline-block'}
                                htmlInfoText={info.htmlInfoText}
                                htmlInfoTitle={info.htmlInfoTitle}
                                offset={offset}
                                qaId={qaId}
                            />
                        ) : (
                            <InfoIconModal
                                className={'inline-block'}
                                htmlInfoText={info.htmlInfoText}
                                htmlInfoTitle={info.htmlInfoTitle}
                            />
                        )}
                    </span>
                )}
            </div>
        </div>
    );
}
