import React from 'react';

import { useClientUrl } from '@/core/features/app/app-atoms';
import { atom, useAtom } from '@/core/features/store/atom-store';

type MinHeightRecordKey = `${string}_${number}`;

const componentMinHeightRegistryAtom = atom<{
    [url: string]: Record<MinHeightRecordKey, null | number>;
}>({});

const useComponentMinHeightRegistry = () => useAtom(componentMinHeightRegistryAtom);

export default function useComponentMinHeightRegistryState(config: {
    componentType: string;
    pageComponentIndex: number;
}) {
    const { componentType, pageComponentIndex } = config;
    const key: MinHeightRecordKey = `${componentType}_${pageComponentIndex}`;

    const [clientUrl] = useClientUrl();
    const [componentMinHeightRegistry, setComponentMinHeightRegistry] = useComponentMinHeightRegistry();

    const setMinHeightState = React.useCallback(
        (minHeight: null | number) => {
            const currentMinHeight = componentMinHeightRegistry[clientUrl]?.[key];
            if (currentMinHeight === minHeight) {
                return;
            }
            setComponentMinHeightRegistry((prev) => ({
                ...prev,
                [clientUrl]: { ...prev[clientUrl], [key]: minHeight },
            }));
        },
        [key, componentMinHeightRegistry, setComponentMinHeightRegistry, clientUrl],
    );

    return {
        minHeightState: componentMinHeightRegistry[clientUrl]?.[key] ?? null,
        setMinHeightState,
    };
}
