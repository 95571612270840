import type { ButtonWrapperProps } from '@/core/components/button/button-wrapper';
import type { OnClickEvent } from '@/core/types/react-overrides';

import { ButtonWrapper } from '@/core/features';

export type ButtonProps = ButtonWrapperProps & {
    title: string;
};

export default function Button(props: ButtonProps) {
    const onClickButton = (event: OnClickEvent) => {
        // prevent user from submitting too often
        if (!props.onClick || props.isLoading || props.disabled || props.isSuccess) {
            return;
        }
        props.onClick(event);
    };

    return (
        <ButtonWrapper
            {...props}
            onClick={onClickButton}
        >
            <span className={'padding-x-5'}>{props.title}</span>
        </ButtonWrapper>
    );
}
