import color from '@/core/features/styles/color';

type WishlistVariant = {
    button: {
        backgroundColor?: string;
        height: number;
        outlineColor?: string;
        paddingTop?: number;
        width: number;
    };
    icon: {
        activeFillColor?: string;
        activeHoverColor?: string;
        activeStrokeColor?: string;
        height: number;
        inactiveFillColor?: string;
        inactiveHoverColor?: string;
        inactiveStrokeColor?: string;
        shadowEffect?: string;
        strokeWidth?: number;
        width: number;
    };
};

const transparent: WishlistVariant = {
    button: {
        height: 40,
        width: 40,
    },
    icon: {
        activeFillColor: color('persianRed'),
        activeHoverColor: color('black-05'),
        activeStrokeColor: color('white'),
        height: 28,
        inactiveFillColor: color('black-05'),
        inactiveHoverColor: color('persianRed'),
        inactiveStrokeColor: color('white'),
        shadowEffect: `drop-shadow(0px 1px 2px ${color('black-02')})`,
        strokeWidth: 2,
        width: 31,
    },
};

const plain: WishlistVariant = {
    button: {
        height: 40,
        width: 40,
    },
    icon: {
        activeFillColor: color('persianRed'),
        activeStrokeColor: color('persianRed'),
        height: 17,
        inactiveFillColor: color('white'),
        inactiveStrokeColor: color('mineShaft'),
        width: 19,
    },
};

const plainRounded: WishlistVariant = {
    button: {
        backgroundColor: color('white'),
        height: 34,
        outlineColor: color('nobel'),
        paddingTop: 2,
        width: 34,
    },
    icon: {
        ...plain.icon,
        inactiveStrokeColor: color('mineShaft'),
    },
};

export const wishlistVariants = { plain, plainRounded, transparent };
