import type { KeyFactProps } from '@/core/features/key-fact/key-fact';

import { Headline, KeyFact } from '@/core/features';

export type KeyFactsProps = {
    gap?: number;
    hasCustomOffset?: boolean;
    isDesktopView?: boolean;
    keyFacts: {
        htmlText?: string;
        iconUrl: null | string;
        info?: {
            htmlInfoText: string;
            htmlInfoTitle: null | string;
        } | null;
        text?: string;
    }[];
    lineHeight?: number;
    offset?: [number, number];
    qaId?: string;
    shouldReserveIconSpace?: boolean;
    showIcon?: boolean;
    title?: null | string;
} & Pick<KeyFactProps, 'fontSize' | 'iconGap' | 'iconSize' | 'truncation'>;

export default function KeyFacts({
    fontSize,
    gap = 8,
    iconGap,
    iconSize = 18,
    isDesktopView = false,
    keyFacts,
    lineHeight,
    offset = [0, 36],
    qaId,
    shouldReserveIconSpace = true,
    showIcon, // UPDATE [bc-1] remove with KeyfactsV2 when icons become nullable in keyfact
    title = null,
    truncation = 'none', // wrap = 'nowrap',
}: KeyFactsProps) {
    if (keyFacts.length === 0) {
        return null;
    }

    const reserveIconSpace = shouldReserveIconSpace ? keyFacts.some((fact) => fact.iconUrl) : false;

    return (
        <div>
            {title && (
                <Headline
                    clamp
                    marginBottom={10}
                    title={title}
                />
            )}
            <div
                className={'gap'}
                style={{ gap }}
            >
                {keyFacts.map((fact, index) => {
                    return (
                        <KeyFact
                            fontSize={fontSize}
                            iconGap={iconGap}
                            iconSize={iconSize}
                            isDesktopView={isDesktopView}
                            key={`${fact.text ?? fact.htmlText}${index}`}
                            lineHeight={lineHeight}
                            offset={offset}
                            qaId={qaId}
                            reserveIconSpace={reserveIconSpace}
                            showIcon={showIcon}
                            truncation={truncation}
                            {...fact}
                        />
                    );
                })}
            </div>
        </div>
    );
}
