import color from '@/core/features/styles/color';

import styles from './shimmer-box.module.scss';

export type ShimmerBoxProps = {
    borderColor?: string;
    borderRadius?: string;
    borderWidth?: string;
    color?: string;
    height?: string;
    marginBottom?: string;
    marginTop?: string;
    noShrink?: boolean;
    size?: string;
    width?: string;
};

export default function ShimmerBox({
    borderColor = color('alto'),
    borderRadius = '5px',
    borderWidth,
    color: backgroundColor,
    height,
    marginBottom,
    marginTop,
    noShrink = false,
    size = '100%',
    width,
}: ShimmerBoxProps) {
    return (
        <div
            className={styles.shimmerBox}
            style={{
                backgroundColor,
                border: borderWidth ? `${borderWidth} solid ${borderColor}` : undefined,
                borderRadius,
                flexShrink: noShrink ? '0' : 'unset',
                height: height ?? size,
                marginBottom: marginBottom,
                marginTop: marginTop,
                width: width ?? size,
            }}
        />
    );
}
