import type { KeyFactsProps } from '@/core/components/key-facts/key-facts';

import React from 'react';

import { HtmlTextWrapper, IconImage, InfoIconModal, InfoIconTooltip } from '@/core/features';
import useWindowResize from '@/core/features/event-listener/use-window-resize';

export type KeyFactsHorizontalProps = {
    bulletAlignment?: 'vertical-middle' | 'vertical-top';
    bulletSpacing?: 'padding-left-5 padding-right-5' | 'padding-left-10 padding-right-10';
    fontSize?: 'font-size-12' | 'font-size-14' | 'font-size-15' | 'font-size-16';
    iconSize?: 15 | 16;
    isDesktopView?: boolean;
    keyfacts: KeyFactsProps['keyFacts'];
    lineClamp?: 'line-clamp-4';
    lineHeight?: 'line-height-16' | 'line-height-24';
};

const LineHeightThreshold = 10;

const isCurrentElementFirstInLine = (currentElement: HTMLElement, lastElement: HTMLElement): boolean => {
    return (
        currentElement.offsetTop + currentElement.offsetHeight - (lastElement.offsetTop + lastElement.offsetHeight) >
        LineHeightThreshold
    );
};

const isCurrentElementLastInLine = (currentElement: HTMLElement, lastElement: HTMLElement): boolean => {
    return currentElement.offsetTop - lastElement.offsetTop > LineHeightThreshold;
};

export default function KeyFactsHorizontal({
    bulletAlignment = 'vertical-top',
    bulletSpacing = 'padding-left-10 padding-right-10',
    fontSize = 'font-size-15',
    iconSize = 16,
    isDesktopView = false,
    keyfacts,
    lineClamp,
    lineHeight = 'line-height-24',
}: KeyFactsHorizontalProps) {
    const keyfactsRef = React.useRef<HTMLDivElement | null>(null);

    const computeBulletVisibility = React.useCallback((element: Element | HTMLDivElement | HTMLSpanElement) => {
        let lastElement: Node | null = null;
        Array.from(element.childNodes).forEach((currentElement) => {
            if (!lastElement) {
                // eslint-disable-next-line fp/no-mutation
                lastElement = currentElement;
                return;
            }

            if (
                (!(currentElement instanceof HTMLSpanElement) && !(currentElement instanceof HTMLDivElement)) ||
                (!(lastElement instanceof HTMLSpanElement) && !(lastElement instanceof HTMLDivElement))
            ) {
                return;
            }

            currentElement.style.removeProperty('display');
            currentElement.classList.remove('text-transparent');

            // Bullet is last Element in line
            if (lastElement.classList.contains('bullet') && isCurrentElementLastInLine(currentElement, lastElement)) {
                lastElement.classList.add('text-transparent', 'font-size-16'); // reserves space to avoid reflow
            }
            // Bullet is first Element in line
            else if (
                currentElement.classList.contains('bullet') &&
                isCurrentElementFirstInLine(currentElement, lastElement)
            ) {
                currentElement.style.setProperty('display', 'none'); // don't reserve space
            }

            // eslint-disable-next-line fp/no-mutation
            lastElement = currentElement;
        });
    }, []);

    React.useEffect(() => {
        const keyFactResizeObserver = new ResizeObserver((entries: ResizeObserverEntry[]) => {
            entries.forEach((entry) => {
                computeBulletVisibility(entry.target);
            });
        });

        const ref = keyfactsRef.current;
        if (ref === null || !(ref instanceof HTMLDivElement)) {
            return;
        }
        keyFactResizeObserver.observe(ref);
        computeBulletVisibility(ref);

        return () => {
            keyFactResizeObserver.unobserve(ref);
        };
    }, [computeBulletVisibility]);

    const SCREEN_MIN_WIDTH = 800;
    const screenSize = useWindowResize();
    const screenWidth = screenSize.windowSize.innerWidth;
    const isSmallOverlay = screenWidth <= SCREEN_MIN_WIDTH;

    return (
        <div
            className={`${fontSize} ${lineHeight} ${lineClamp ?? ''}`}
            ref={keyfactsRef}
        >
            {keyfacts.map(({ htmlText, iconUrl, info, text }, index) => {
                return (
                    <React.Fragment key={`${text}-${iconUrl}`}>
                        <span>
                            {iconUrl && (
                                <span className={'inline relative text-nowrap margin-right-5 vertical-middle'}>
                                    <IconImage
                                        alt={''}
                                        height={iconSize}
                                        inline={true}
                                        url={iconUrl}
                                        width={iconSize}
                                    />
                                    &#xfeff;
                                </span>
                            )}
                            {htmlText && <HtmlTextWrapper htmlText={htmlText} />}
                            {text}
                            {info && (
                                <span
                                    className={'inline relative text-nowrap margin-left-5 vertical-middle'}
                                    data-qa-id={'qa-key-fact-info-icon'}
                                >
                                    &#xfeff;
                                    {isDesktopView ? (
                                        <InfoIconTooltip
                                            className={'inline-block'}
                                            htmlInfoText={info.htmlInfoText}
                                            htmlInfoTitle={info.htmlInfoTitle}
                                            offset={isSmallOverlay ? [10, 10] : [10, 20]}
                                            tippyConfig={{
                                                placement: isSmallOverlay ? 'bottom' : 'auto',
                                            }}
                                        />
                                    ) : (
                                        <InfoIconModal
                                            className={'inline-block'}
                                            htmlInfoText={info.htmlInfoText}
                                            htmlInfoTitle={info.htmlInfoTitle}
                                        />
                                    )}
                                </span>
                            )}
                        </span>
                        {index < keyfacts.length - 1 && (
                            <span className={`inline-block bullet height-100 ${bulletAlignment} ${bulletSpacing}`} />
                        )}
                    </React.Fragment>
                );
            })}
        </div>
    );
}
