import type { TippyConfig } from '@/core/features/tooltip/tooltip';

import { InfoIcon, Tooltip, TooltipTextBox } from '@/core/features';
import color from '@/core/features/styles/color';

type Info = {
    className?: string;
    flip?: boolean;
    htmlInfoText: string;
    htmlInfoTitle: null | string;
    iconColor?: string;
    iconSize?: number;
    offset?: [number, number];
    qaId?: string;
    tippyConfig?: TippyConfig;
};

export default function InfoIconTooltip({
    className,
    flip,
    htmlInfoText,
    htmlInfoTitle,
    iconColor = color('nobel'),
    iconSize = 14,
    offset = [0, 10],
    qaId,
    tippyConfig,
}: Info) {
    return (
        <Tooltip
            ariaLabel={htmlInfoTitle ?? htmlInfoText}
            className={className}
            content={
                <TooltipTextBox
                    htmlText={htmlInfoText}
                    htmlTitle={htmlInfoTitle}
                />
            }
            flip={flip}
            qaId={qaId}
            tippyConfig={{
                offset: offset,
                ...tippyConfig,
            }}
        >
            <InfoIcon
                color={iconColor}
                height={iconSize}
                width={iconSize}
            />
        </Tooltip>
    );
}
