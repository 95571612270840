import React from 'react';

import styles from './columns.module.scss';

export interface ColumnsProps {
    children?: React.ReactNode;
    className?: string;
    count: number;
    disableLastItemStretch?: boolean;
    gap?: number;
    noSpace?: boolean;
}

const getFractionalUnitByCols = (count: number, noSpace: boolean): string => {
    return Array.from({ length: count })
        .map(() => (noSpace ? '0fr' : '1fr'))
        .join(' ');
};

const Columns = ({ children, className = '', count, disableLastItemStretch = false, gap, noSpace }: ColumnsProps) => {
    const columnStyle = {
        display: 'grid',
        gridGap: `${gap ?? 2}px`,
        gridTemplateColumns: getFractionalUnitByCols(count, noSpace ?? false),
        gridTemplateRows: '1fr',
    };

    const lastItemStretchStyle = () => {
        if (count === 2 && !disableLastItemStretch) {
            return styles.col2Stretch;
        }
        if (count === 3 && !disableLastItemStretch) {
            return styles.col3Stretch;
        }
        if (count === 4 && !disableLastItemStretch) {
            return styles.col4Stretch;
        }
        return styles.noStretch;
    };

    return (
        <div
            className={`${lastItemStretchStyle()} ${className ?? ''}`}
            style={columnStyle}
        >
            {children}
        </div>
    );
};

export default Columns;
