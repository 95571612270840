import React from 'react';

import SessionStorage from '@/core/features/store/session-storage';
import useWireframeLoaded from '@/core/features/wireframe/use-wireframe-loaded';

const wishlistPendingSessionStorage = new SessionStorage<number>('wishlist', { defaultKey: 'pending' });

type UseWishlistLoginRefreshProps = {
    callback: () => void;
    wishlistId: number;
};

export default function useWishlistLoginRefresh({ callback, wishlistId }: UseWishlistLoginRefreshProps) {
    const registerLoginRefreshHandler = React.useCallback(() => {
        wishlistPendingSessionStorage.set(wishlistId);
    }, [wishlistId]);

    useWireframeLoaded(() => {
        const storedWishlistId = wishlistPendingSessionStorage.get();

        if (storedWishlistId !== wishlistId) {
            return;
        }

        wishlistPendingSessionStorage.remove();
        setTimeout(callback, 0);
    });

    return { registerLoginRefreshHandler };
}
