import React from 'react';

import { HtmlTextWrapper } from '@/core/features';
import { bemModule } from '@/core/utils/bem-classname';

import styles from './headline.module.scss';

type HeadlinePropsTitle = {
    htmlTitle?: never;
    title: string;
};
type HeadlinePropsHtmlTitle = {
    htmlTitle: string;
    title?: never;
};

export type HeadlineProps = {
    center?: boolean;
    clamp?: boolean;
    color?: string;
    fontSize?: number;
    fontWeight?: 'bold' | 'normal';
    marginBottom?: number;
    marginTop?: number;
    padding?: number;
    type?: 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6' | 'none';
} & (HeadlinePropsHtmlTitle | HeadlinePropsTitle);

const bem = bemModule(styles);

export default function Headline({
    center,
    clamp,
    color,
    fontSize = 16,
    fontWeight = 'bold',
    htmlTitle,
    marginBottom = 10,
    marginTop = 0,
    padding,
    title,
    type = 'h2',
}: HeadlineProps) {
    const normalizedType = !type || type === 'none' ? 'span' : type;
    const children = title ?? (
        <HtmlTextWrapper
            htmlText={htmlTitle}
            inline
        />
    );

    return React.createElement(
        normalizedType,
        {
            className: bem(styles.headline, { center, clamp }),
            style: { color, fontSize, fontWeight, marginBottom, marginTop, padding },
        },
        children,
    );
}
