import React from 'react';

import { classnames } from '@/core/utils/bem-classname';

import styles from './html-text-wrapper.module.scss';

export type HtmlTextWrapperProps = {
    className?: string;
    htmlText: string;
    inline?: boolean;
    style?: React.CSSProperties;
};

export default function HtmlTextWrapper({ className, htmlText, inline = false, style }: HtmlTextWrapperProps) {
    const elementType = inline ? 'span' : 'div';
    return React.createElement(elementType, {
        className: classnames(styles.htmlTextWrapper, className),
        dangerouslySetInnerHTML: { __html: htmlText },
        style: {
            ...style,
            display: className?.includes('line-clamp-') ? '-webkit-box' : undefined,
        },
    });
}
