import type { IconWrapperProps } from '@/core/components/icon/icon-wrapper';
import type { SVGIconProps } from '@/core/components/icon/svg-icon';

import SpinnerIcon from '@/assets/icons/spinner';
import color from '@/core/features/styles/color';

import styles from './loading-spinner.module.scss';

export default function LoadingSpinnerIcon(props: SVGIconProps & IconWrapperProps) {
    const { color: iconColor = color('black') } = props;
    return (
        <div className={styles.loadingSpinner}>
            <SpinnerIcon
                color={iconColor}
                height={props.height}
                onClick={props.onClick}
                width={props.width}
            />
        </div>
    );
}
