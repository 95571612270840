import type { RouterLinkProps } from '@/core/features/router/router-link';
import type { SafeOmit } from '@/core/types/safe-omit';

import useDynamicPageQueryPrefetch from '@/core/features/a-dynamic-page/hooks/use-dynamic-page-query-prefetch';
import { usePrefetchSetting } from '@/core/features/link/prefetch-setting-state';
import { RouterLink } from '@/core/features/router/router-link';

export default function DynamicPageLink(
    props: SafeOmit<RouterLinkProps, 'prefetchConfig'> & { disablePrefetch?: boolean; isMutation?: boolean },
) {
    const { disablePrefetch, ...routerLinkProps } = props;
    const { prefetch } = useDynamicPageQueryPrefetch(props.href);
    const isPrefetchEnabled = usePrefetchSetting() ?? true;

    return (
        <RouterLink
            {...routerLinkProps}
            prefetchConfig={{
                delay: 500,
                enabled: isPrefetchEnabled && !disablePrefetch,
                handler: () => prefetch(props.href),
            }}
        />
    );
}
