import React from 'react';

import zIndex from '@/core/features/styles/z-index';

import styles from './loading-layer.module.scss';

export default function LoadingLayer() {
    const containerElementRef = React.useRef<HTMLDivElement>(null);

    return (
        <div
            className={`absolute-full ${styles.loadingLayer}`}
            ref={containerElementRef}
            style={{ zIndex: zIndex('loadingLayer') }}
        />
    );
}
