import type { IUnifiedLoginLayerResponse, IUnifiedLoginResponseData } from '@/core/features/wireframe/wireframe-types';

import { WIREFRAME_ULI_LOGIN_LAYER_EVENT, WIREFRAME_UNIFIED_LOGIN_EVENT } from '@packages/wireframe/src/constants';

import { logger } from '@/core/features/logger/logger';

// unified Login integration docs: https://confluence.check24.de/display/HOULI/Integration+Guide

function checkUnifiedLoginLayerEventSuccess(state?: string): boolean {
    return state !== undefined && state !== null && state !== '' && state !== 'browserback';
}

export function registerUnifiedLogin({
    onError,
    onSuccess,
    triggerOnce = false,
    withLoginLayer = false,
}: {
    onError?: (event: IUnifiedLoginResponseData) => void;
    onSuccess?: (event: IUnifiedLoginResponseData) => void;
    triggerOnce?: boolean;
    withLoginLayer?: boolean;
}) {
    const eventHandler = (event: unknown) => {
        const unifiedLoginEvent = (event as IUnifiedLoginLayerResponse)?.detail?.ul;

        if (checkUnifiedLoginLayerEventSuccess(unifiedLoginEvent?.STATE)) {
            onSuccess?.(unifiedLoginEvent);
        } else {
            onError?.(unifiedLoginEvent);
        }
    };

    if (withLoginLayer) {
        addEventListener(WIREFRAME_ULI_LOGIN_LAYER_EVENT, eventHandler, { once: triggerOnce });
        return () => removeEventListener(WIREFRAME_ULI_LOGIN_LAYER_EVENT, eventHandler);
    } else {
        addEventListener(WIREFRAME_UNIFIED_LOGIN_EVENT, eventHandler, { once: triggerOnce });
        return () => removeEventListener(WIREFRAME_UNIFIED_LOGIN_EVENT, eventHandler);
    }
}

export function openUnifiedLogin({ isApp, retries = 0 }: { isApp: boolean; retries?: number }) {
    if (retries > 3) {
        logger.error('Could not open Unified Login after 3 retries, aborting');
        return;
    }
    if (isApp) {
        if (window.C24App?.unifiedLoginTriggered) {
            return;
        }
        if (!window.C24App?.triggerUnifiedLogin) {
            setTimeout(() => {
                openUnifiedLogin({ isApp, retries: retries + 1 });
            }, 2000);
            return;
        }
        window.C24App.triggerUnifiedLogin('app');
    } else {
        window.Check24.uliloginlayer.open();
    }
}
