import type { LinkType } from '@/core/features/link/link';
import type { OnClickEvent } from '@/core/types/react-overrides';

import { ConditionalLinkWrapper, LoadingSpinnerIcon } from '@/core/features';
import { useDeviceoutput } from '@/core/features/cookies/use-device-output';
import color from '@/core/features/styles/color';
import { bemModule } from '@/core/utils/bem-classname';

import styles from './button-wrapper.module.scss';

const bem = bemModule(styles);

export const ButtonSizes = {
    big: 'big',
    medium: 'medium',
    small: 'small',
};

export const ButtonVariants = {
    primary: 'primary',
    secondary: 'secondary',
};

export type ButtonWrapperProps = {
    className?: string;
    disabled?: boolean;
    fontSize?: 14 | 16 | 18 | 20;
    fontWeight?: 'bold' | 'normal';
    hasLoadingStyle?: boolean;
    height?: number;
    isLoading?: boolean;
    isSuccess?: boolean;
    noShadow?: boolean;
    onClick?: (event: OnClickEvent) => void;
    qaId?: string;
    size?: keyof typeof ButtonSizes;
    style?: React.CSSProperties;
    type?: 'button' | 'reset' | 'submit';
    variant?: keyof typeof ButtonVariants;
    width?: 'fit-content' | number;
} & ( // if href is given, also a linkType has to be set
    | { disablePrefetch?: boolean; href: string; linkType: LinkType }
    | { disablePrefetch?: never; href?: never; linkType?: never }
);

export default function ButtonWrapper(props: React.PropsWithChildren<ButtonWrapperProps>) {
    const { isDesktopView } = useDeviceoutput();
    const {
        children,
        className,
        disablePrefetch,
        disabled = false,
        fontSize = 20,
        fontWeight = 'normal',
        hasLoadingStyle = false,
        height = 50,
        href,
        isLoading,
        linkType = 'internal',
        noShadow = isDesktopView,
        onClick,
        qaId,
        size,
        style,
        type = 'button',
        variant,
        width,
    } = props;

    const handleWidth = () => {
        if (width) {
            if (width === 'fit-content') {
                return 'fit-content';
            } else if (typeof width === 'number') {
                return width + 'px';
            }
        }
        return '100%';
    };

    const onClickButton = (event: OnClickEvent) => {
        if (!onClick) {
            return;
        }
        onClick(event);
    };
    return (
        <ConditionalLinkWrapper
            disablePrefetch={disablePrefetch}
            disabled={disabled}
            fullWidth
            href={href}
            isLoading={isLoading}
            linkType={linkType}
        >
            <button
                className={`flex-center ${bem(styles.button, {
                    disabled,
                    hasLoadingStyle,
                    noShadow,
                    size,
                    variant,
                })} ${className ? className : ''}`}
                data-qa-id={qaId}
                disabled={disabled}
                onClick={onClickButton}
                style={{
                    '--fontSize': `${fontSize}px`,
                    '--fontWeight': fontWeight,
                    '--maxHeight': `${height}px`,
                    '--minHeight': `${height}px`,
                    '--width': `${handleWidth()}`,
                    ...style,
                }}
                type={type}
            >
                {children}
                {isLoading && (
                    <LoadingSpinnerIcon
                        color={color('white')}
                        height={hasLoadingStyle ? 20 : 25}
                        width={hasLoadingStyle ? 20 : 25}
                    />
                )}
            </button>
        </ConditionalLinkWrapper>
    );
}
