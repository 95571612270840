import type { LinkClickInterface } from '@/core/features/router/router-link';

import React from 'react';

import { ScrollToComponentLink } from '@/core/features';
import { useClientUrl } from '@/core/features/app/app-atoms';
import { useDeviceoutput } from '@/core/features/cookies/use-device-output';
import {
    forwardLinkUrlToAppNative,
    willForwardToAppNative,
} from '@/core/features/link/app-native-link-forward-service';
import DynamicPageLink from '@/core/features/link/dynamic-page-link';
import { logger } from '@/core/features/logger/logger';
import { useRouterEvents } from '@/core/features/router/router-events';
import { savePageScrollPosition } from '@/core/features/scroll/page-scroll-restoration/page-scroll-position-service';
import { bemModule } from '@/core/utils/bem-classname';

import styles from './link.module.scss';

const bem = bemModule(styles);

/**
 * LinkType
 * component: scroll to component on same page via component iri
 * external: linkouts to external sites
 * internal: linkouts to internal pages
 * vanilla: vanilla anchor html tag acting as a linkout but works both internal or external
 * vertical: linkouts to other vertical pages so they can open either in a new tab or not based on device (INDI-3559)
 */
export type LinkType = 'external' | 'internal' | 'scroll' | 'vanilla' | 'vertical';

export type LinkProps = React.PropsWithChildren<
    {
        className?: string;
        disablePrefetch?: boolean;
        disabled?: boolean;
        fullHeight?: boolean;
        fullWidth?: boolean;
        highlight?: boolean;
        href: string;
        isFlex?: boolean;
        isLoading?: boolean;
        /** Will disbale TanStack Query cache for current url */
        isMutation?: boolean;
        qaId?: string;
        style?: React.CSSProperties;
        target?: '_blank' | '_parent' | '_self' | '_top';
    } & (
        | {
              linkType: 'external' | 'vertical';
              onClick?: LinkClickInterface;
              relType?: 'follow' | 'nofollow';
          }
        | {
              linkType: 'internal' | 'vanilla';
              onClick?: LinkClickInterface;
              relType?: never;
          }
        | {
              linkType: 'scroll';
              onClick?: React.MouseEventHandler<HTMLButtonElement>;
              relType?: never;
          }
    )
>;

export default function Link(props: LinkProps) {
    const [clientUrl] = useClientUrl();
    const routerEvents = useRouterEvents();
    const { isApp } = useDeviceoutput();
    const { className, disabled, fullHeight, fullWidth, highlight, href, isFlex, isLoading, relType, style } = props;

    // always set links with parameters to nofollow so crawlers won't follow the link which will be noindex beacuse of url parameters
    const relTypeInternal = !relType && href.includes('?') ? 'nofollow' : relType;

    if (!href) {
        logger.error(`href is '${href}' in Link Component`, {
            additionalData: JSON.stringify(props),
            url: clientUrl,
        });
        return null;
    }

    const mergedClassName = `${className ?? ''} ${bem(styles.link, { fullHeight, fullWidth, highlight, isFlex })}`;

    if (disabled || isLoading) {
        return (
            <span
                className={mergedClassName}
                style={style}
            >
                {props.children}
            </span>
        );
    }

    if (isApp && willForwardToAppNative(href)) {
        return (
            <a
                className={mergedClassName}
                data-is-appnative={true}
                href={href}
                onClick={(event) => forwardLinkUrlToAppNative(event, href)}
                rel={relTypeInternal}
                style={style}
            >
                {props.children}
            </a>
        );
    }

    const { disablePrefetch, isMutation, linkType, onClick, qaId, target } = props;

    const onLinkClick = (event: React.MouseEvent<HTMLAnchorElement, Event>) => {
        savePageScrollPosition(clientUrl, { linkType });

        if (linkType !== 'internal' && target !== '_blank') {
            routerEvents.emit('linkout');
        }

        if (linkType === 'scroll') {
            return;
        }

        return onClick?.(event) ?? true;
    };

    if (linkType === 'scroll') {
        return (
            <ScrollToComponentLink
                className={mergedClassName}
                componentIri={href}
                onClick={onClick}
                qaId={qaId}
                style={style}
            >
                {props.children}
            </ScrollToComponentLink>
        );
    }

    if (linkType === 'internal') {
        return (
            <DynamicPageLink
                className={mergedClassName}
                disablePrefetch={disablePrefetch}
                href={href}
                isMutation={isMutation}
                onClick={onLinkClick}
                qaId={qaId}
                rel={relTypeInternal}
                style={style}
                target={target}
            >
                {props.children}
            </DynamicPageLink>
        );
    }

    // return a plain link
    if (linkType === 'vanilla') {
        return (
            <a
                className={mergedClassName}
                data-qa-id={qaId}
                href={href}
                onClick={onLinkClick}
                style={style}
                target={target}
            >
                {props.children}
            </a>
        );
    }

    /**
     * External Links
     */
    const relTypeExternal =
        relType === 'follow' ? 'external noreferrer noopener' : 'external noreferrer noopener nofollow';

    // ignore target (no _blank) if linkType is vertical and isApp
    const deviceSpecificTarget: LinkProps['target'] = linkType === 'vertical' && isApp ? undefined : target;

    return (
        <a
            className={mergedClassName}
            data-qa-id={qaId}
            href={href}
            onClick={onLinkClick}
            rel={relTypeExternal}
            style={style}
            target={deviceSpecificTarget}
        >
            {props.children}
        </a>
    );
}
